import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { HiX } from "react-icons/hi";
import { Link } from "react-router-dom";

import logo from "./lena.png"; // Adjust the path as needed
import "./styles.scss";

const data = [
  { label: "HOME", to: "/" },
  { label: "ABOUT", to: "/about" },
  { label: "SKILLS", to: "/skills" },
  { label: "RESUME", to: "/resume" },
  { label: "PORTFOLIO", to: "/portfolio" },
  // { label: "CONTACT", to: "/contact" },
];

const Navbar = () => {
  const [toggleIcon, setToggleIcon] = useState(false);
  const [loading, setLoading] = useState(true); // Preloader state

  const handleToggleIcon = () => {
    setToggleIcon(!toggleIcon);
  };

  useEffect(() => {
    // Simulate loading delay (use this if you want to show the loader for a short time)
    setTimeout(() => {
      setLoading(false); // Hide preloader after loading
    }, 3000); // Adjust this time as needed or remove it for actual page loading time
  }, []);

  return (
    <div>
      {loading ? (
        <div className="preloader">
          <img src={logo} alt="Logo" className="preloader__logo" />
        </div>
      ) : (
        <nav className="navbar">
          <div className="navbar__container">
            <div className="navbar__container__logo">
              <img src={logo} alt="Logo" className="navbar__logo-image" />
            </div>
          </div>
          <ul className={`navbar__container__menu ${toggleIcon ? "active" : ""}`}>
            {data.map((item, key) => (
              <li key={key} className="navbar__container__menu__item">
                <Link className="navbar__container__menu__item__links" to={item.to}>
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
          <div className="nav-icon" onClick={handleToggleIcon}>
            {toggleIcon ? <HiX size={30} /> : <FaBars size={30} />}
          </div>
        </nav>
      )}
    </div>
  );
};

export default Navbar;
